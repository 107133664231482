import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "tw-elements/dist/plugin";

import Home from "./pages/Home";
import AuthProvider from "./context/auth";
import Profile from "./components/Profile";
import PrivateRoute from "./components/PrivateRoute";
import Welcome from "./pages/Welcom";
import Imessage from "./pages/Imessage";
import TermsOfService from "./components/TermsOfService";
import SendComplete from "./components/SendComplete";
import RegisterRe from "./components/RegisterRe";
import Map from "./pages/Map";
import Beans from "./components/Beans";
import AdminSetting from "./pages/AdminSetting";
import TestPlace from "./components/TestPlace";
import SekaimuraMap from "./pages/SekaimuraMap";
import Account from "./components/Account";
import MyRequestShop from "./components/MyRequestShop";
import Product from "./components/Product";
import Ddp from "./components/Ddp";
import Movies from "./components/Movies";
import MyPage from "./components/MyPage";
import Events from "./components/Events";
import Event from "./components/Event";
import Groups from "./components/Groups";
import Bases from "./components/Bases";
import Base from "./components/Base";
import Group from "./components/Group";
import Modal from "./components/Modal";
import Deal from "./components/Deal";
import Release from "./pages/Release";
import UnderConstruction from "./components/UnderConstruction";
import ApprovalPending from "./components/ApprovalPending";
import ScrollTop from "./components/ScrollTop";
import DisplayFirstLoginInfomation from "./components/DisplayFirstLoginInfomation";
import HeaderContainerMixIN from "./components/HeaderContainerMixIN";
import AllNotifications from "./components/AllNotifications";
import Manuals from "./components/Manuals";
import { memo, useEffect } from "react";
import DefaultRoute from "./components/DefaultRoute";
import TestChat from "./components/TestChat";
import QuestionForAdmin from "./components/QuestionForAdmin";
import SekaiMap from "./components/SekaiMap";
import FindPassion from "./components/FindPassion";
import Announce from "./components/Announce";
import Lexical from "./components/Lexical";
import MovieChannel from "./components/MovieChannel";
import Movie from "./components/Movie";
import ConfirmLngLat from "./pages/ConfirmLngLat";
import Test from "./components/Test";
import MovieContainer from "./components/MovieContainer";
import MoviesContainer from "./components/MoviesContainer";
import MovieChannelContainer from "./components/MovieChannelContainer";
import ComparisonMembers from "./components/ComparisonMembers";
import Ddps from "./components/Ddps";
import TestTest from "./components/TestTest";
import InPost from "./components/InPost";
import User from "./User";
import ShopApplication from "./components/ShopApplication";
import ThanksForApplication from "./components/ThanksForAllication";
import Informations from "./components/Informations";
import Supports from "./components/Supports";
import EditorRoom from "./components/EditorRoom";
import { useAppLifecycle } from "./hooks/useAppLifecycle";
import { useAuthListener } from "./hooks/useAuthListener";
import { useUpdateDeviceStatusOnVisibilityChange } from "./hooks/useUpdateDeviceStatusOnVisibilityChange";
import { requestForToken } from "./components/firebase";
import DefinitionDirectionPurpose from "./components/DefinitionDirectionPurpose";
import MusicPlayer from "./components/MusicPlayer";
import Disaster from "./components/Disaster";

function App() {
  useAppLifecycle();
  useAuthListener(); // デバイス登録
  useUpdateDeviceStatusOnVisibilityChange(); // VisibilityChange関数

  useEffect(() => {
    requestForToken();
  }, []);

  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <DisplayFirstLoginInfomation />
          <ScrollTop />
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/sendcomplete" element={<SendComplete />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/ddp" element={<Ddp />} />
            <Route path="/ddp/:search" element={<Ddp />} />
            <Route path="/release" element={<Release />} />
            <Route path="/event/:id" element={<Event />} />
            <Route path="/confirmLngLat" element={<ConfirmLngLat />} />
            <Route path="/map" element={<Map />} />
            <Route path="/movies" element={<MoviesContainer />} />
            <Route path="/movie/:id" element={<MovieContainer />} />
            <Route
              path="/movieChannel/:id"
              element={<MovieChannelContainer />}
            />
            <Route path="/ddps" element={<Ddps />} />
            <Route path="/test" element={<Test />} />
            <Route path="/shopApplication" element={<ShopApplication />} />
            <Route
              path="/thanksForApplication"
              element={<ThanksForApplication />}
            />
            <Route path="/definitiondirectionpurpose" element={<DefinitionDirectionPurpose />} />
  

            <Route element={<PrivateRoute />}>
              <Route path="/register_re" element={<RegisterRe />} />
              <Route path="*" element={<Home />} />
              <Route path="/profile/:uid" element={<Account />} />
              <Route path="/home" element={<Home />} />
              <Route path="/manuals" element={<Manuals />} />
              <Route path="/adminsetting" element={<AdminSetting />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/sekaiMap" element={<SekaiMap />} />
              <Route path="/findPassion" element={<FindPassion />} />
              <Route path="/deal/:id" element={<Deal />} />
              <Route path="/chat" element={<Imessage />} />
              <Route path="/allnotifications" element={<AllNotifications />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/groups/:search" element={<Groups />} />
              <Route path="/group/:id" element={<Group />} />
              <Route path="/approvalpending" element={<ApprovalPending />} />
              <Route path="/questionforadmin" element={<QuestionForAdmin />} />
              <Route path="/lexical" element={<Lexical />} />
              <Route path="announce/:id" element={<Announce />} />
              <Route path="/events" element={<Events />} />
              <Route path="informations" element={<Informations />} />
              <Route path="informations/:id" element={<Supports />} />
              {/* <Route path="/movie/:id" element={<Movie />} /> */}
              {/* <Route path="/movies" element={<Movies />} /> */}
              {/* <Route path="/movieChannel/:id" element={<MovieChannel />} /> */}
              <Route path="/bases" element={<Bases />} />
              <Route path="/base/:id" element={<Base />} />
              <Route path="/mypage/:id" element={<MyPage />} />
              <Route path="/user/:id" element={<User />} />
              <Route path="/mapPoint/:id" element={<MyRequestShop />} />
              <Route path="/post/:id" element={<InPost />} />
              <Route path="/editorTest" element={<EditorRoom />} />
              <Route path="/testtest" element={<TestTest />} />
              <Route path="/music" element={<MusicPlayer />} />
              <Route path="/disaster/:id" element={<Disaster />} />
              <Route
                path="/comparisonMembers"
                element={<ComparisonMembers />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default memo(App);

// react-router-dom v6 PrivateRoute
// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
