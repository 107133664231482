import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../api/firebase"; // Firebase初期化ファイル
import { useParams } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api"; // LoadScript をインポート

const libraries = ["places"]; // 必要なライブラリを指定

const Disaster = () => {
  const [disasterData, setDisasterData] = useState(null);
  const [openSituation, setOpenSituation] = useState(false);
  const [selectedSituations, setSelectedSituations] = useState([]);
  const [formData, setFormData] = useState({
    postalCode: "",
    prefecture: "",
    city: "",
    remainingAddress: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchDisasterData();
  }, []);

  // Firestoreから特定の災害情報を取得
  const fetchDisasterData = async () => {
    const docRef = doc(db, "disasters", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setDisasterData(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  // 郵便番号入力時に住所を自動補完
  const handlePostalCodeChange = (e) => {
    const postalCode = e.target.value;
    setFormData({ ...formData, postalCode });

    if (postalCode.length === 7 && window.google) {
      // Geocoderを使って住所を取得
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: postalCode }, (results, status) => {
        if (status === "OK" && results.length > 0) {
          const addressComponents = results[0].address_components;

          // 都道府県と市区町村を抽出
          const prefecture =
            addressComponents.find((comp) =>
              comp.types.includes("administrative_area_level_1")
            )?.long_name || "";
          const city =
            addressComponents.find((comp) => comp.types.includes("locality"))
              ?.long_name || "";

          setFormData((prevData) => ({
            ...prevData,
            prefecture,
            city,
          }));
        } else {
          console.error("Geocode was not successful for the following reason:", status);
        }
      });
    }
  };

  // ラジオボタンの選択変更
  const handleSituationChange = (e) => {
    const value = e.target.value;
    setSelectedSituations((prevSituations) =>
      prevSituations.includes(value)
        ? prevSituations.filter((situation) => situation !== value)
        : [...prevSituations, value]
    );
  };

  // 住所入力フィールドの変更
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // フォームのバリデーションを確認
  useEffect(() => {
    const isValidPostalCode = formData.postalCode.length === 7;
    const hasSituations = selectedSituations.length > 0;
    const isAddressComplete =
      formData.prefecture && formData.city && formData.remainingAddress;

    setIsFormValid(isValidPostalCode && hasSituations && isAddressComplete);
  }, [formData, selectedSituations]);

  // フォームデータをFirestoreに追加（状況）
  const handleSubmitSituation = async () => {
    if (!isFormValid) return;

    try {
      const docRef = doc(db, "disasters", id);
      await updateDoc(docRef, {
        updates: arrayUnion({
          situations: selectedSituations,
          postalCode: formData.postalCode,
          prefecture: formData.prefecture,
          city: formData.city,
          remainingAddress: formData.remainingAddress,
          updatedAt: new Date(),
        }),
      });
      fetchDisasterData();
      handleCloseSituation();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  // ダイアログを開く・閉じる
  const handleClickOpenSituation = () => setOpenSituation(true);
  const handleCloseSituation = () => setOpenSituation(false);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={libraries}>
      <Container className="bg-gray-100 p-4 mt-4 rounded-lg shadow-lg">
        <Typography variant="h4" className="text-gray-800 mb-4">
          2024能登地震 - 災害特設ページ
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenSituation}
        >
          新しい状況を追加
        </Button>

        {/* 状況ダイアログ */}
        <Dialog open={openSituation} onClose={handleCloseSituation}>
          <DialogTitle>状況を追加</DialogTitle>
          <DialogContent>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="道が寸断されていて進入不可"
                    checked={selectedSituations.includes(
                      "道が寸断されていて進入不可"
                    )}
                    onChange={handleSituationChange}
                  />
                }
                label="道が寸断されていて進入不可"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="電気が止まっている"
                    checked={selectedSituations.includes("電気が止まっている")}
                    onChange={handleSituationChange}
                  />
                }
                label="電気が止まっている"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="水道が止まっている"
                    checked={selectedSituations.includes("水道が止まっている")}
                    onChange={handleSituationChange}
                  />
                }
                label="水道が止まっている"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="ガスが止まっている"
                    checked={selectedSituations.includes("ガスが止まっている")}
                    onChange={handleSituationChange}
                  />
                }
                label="ガスが止まっている"
              />
            </FormGroup>

            {/* 郵便番号フィールド */}
            <TextField
              margin="dense"
              label="郵便番号"
              name="postalCode"
              fullWidth
              variant="outlined"
              value={formData.postalCode}
              onChange={handlePostalCodeChange}
            />

            {/* 都道府県、市区町村を自動補完 */}
            <TextField
              margin="dense"
              label="都道府県"
              name="prefecture"
              fullWidth
              variant="outlined"
              value={formData.prefecture}
              disabled
            />
            <TextField
              margin="dense"
              label="市区町村"
              name="city"
              fullWidth
              variant="outlined"
              value={formData.city}
              disabled
            />

            {/* それ以降の住所入力 */}
            <TextField
              margin="dense"
              label="それ以降の住所"
              name="remainingAddress"
              fullWidth
              variant="outlined"
              value={formData.remainingAddress}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSituation} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={handleSubmitSituation}
              color="primary"
              disabled={!isFormValid}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LoadScript>
  );
};

export default Disaster;
