import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from '../api/firebase';

const OperateDisasterPage = () => {
  const [disasterTitle, setDisasterTitle] = useState('');
  const [disasterDescription, setDisasterDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false); // 編集ダイアログ用
  const [disasters, setDisasters] = useState([]);
  const [selectedDisaster, setSelectedDisaster] = useState(null); // 選択された災害

  // Firestoreから既存の災害情報を取得
  const fetchDisasters = async () => {
    const disasterCollection = collection(db, 'disasters');
    const disasterSnapshot = await getDocs(disasterCollection);
    const disasterList = disasterSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setDisasters(disasterList);
  };

  useEffect(() => {
    fetchDisasters();
  }, []);

  // 新しい災害情報を登録
  const handleAddDisaster = async () => {
    if (disasterTitle && disasterDescription) {
      await addDoc(collection(db, 'disasters'), {
        title: disasterTitle,
        description: disasterDescription,
        createdAt: Timestamp.now(),
      });
      setDisasterTitle('');
      setDisasterDescription('');
      fetchDisasters(); // 更新後のデータを取得
      handleClose(); // ダイアログを閉じる
    }
  };

  // 災害の説明を更新する
  const handleUpdateDisaster = async () => {
    if (selectedDisaster) {
      const disasterRef = doc(db, 'disasters', selectedDisaster.id);
      await updateDoc(disasterRef, {
        description: disasterDescription, // 更新する説明
        updatedAt: Timestamp.now(),
      });
      fetchDisasters(); // 更新後のデータを取得
      handleEditClose(); // 編集ダイアログを閉じる
    }
  };

  // 災害を削除する
  const handleDeleteDisaster = async (id) => {
    try {
      await deleteDoc(doc(db, 'disasters', id));
      fetchDisasters(); // 削除後にデータを再取得
    } catch (error) {
      console.error("Error deleting disaster:", error);
    }
  };

  // ダイアログを開く
  const handleClickOpen = () => {
    setOpen(true);
  };

  // ダイアログを閉じる
  const handleClose = () => {
    setOpen(false);
  };

  // 編集ダイアログを開く
  const handleEditOpen = (disaster) => {
    setSelectedDisaster(disaster);
    setDisasterDescription(disaster.description); // 説明を入力フィールドにセット
    setEditOpen(true);
  };

  // 編集ダイアログを閉じる
  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedDisaster(null);
  };

  // 新しいタブでページにジャンプ
  const handleJump = (disasterId) => {
    window.open(`/disaster/${disasterId}`, '_blank'); // 新しいタブで開く
  };

  return (
    <Container className="bg-gray-100 p-4 mt-4 rounded-lg shadow-lg">
      <Typography variant="h4" className="text-gray-800 mb-4">
        災害特設ページ管理
      </Typography>

      {/* 災害登録ダイアログを開くボタン */}
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        災害を登録
      </Button>

      {/* ダイアログ */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>新しい災害を登録</DialogTitle>
        <DialogContent>
          <TextField
            label="災害名"
            variant="outlined"
            fullWidth
            value={disasterTitle}
            onChange={(e) => setDisasterTitle(e.target.value)}
            className="mb-4"
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="災害の説明"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={disasterDescription}
            onChange={(e) => setDisasterDescription(e.target.value)}
            className="mb-4"
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            キャンセル
          </Button>
          <Button
            onClick={handleAddDisaster}
            color="primary"
            disabled={!disasterTitle || !disasterDescription} // 全てのフィールドが埋まっていない場合は無効化
          >
            登録
          </Button>
        </DialogActions>
      </Dialog>

      {/* 登録済み災害の一覧表示 */}
      <div className="mt-6">
        <Typography variant="h6" className="text-gray-800 mb-4">
          登録済みの災害
        </Typography>
        {disasters.length > 0 ? (
          disasters.map((disaster) => (
            <div
              key={disaster.id}
              className="bg-white p-4 mb-4 rounded shadow"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div style={{ flex: 1 }}>
                <Typography variant="h6" className="text-gray-700">
                  {disaster.title}
                </Typography>
                <Typography variant="body1" className="text-gray-600">
                  {disaster.description}
                </Typography>
                <Typography variant="body2" className="text-gray-500">
                  登録日時: {disaster.createdAt.toDate().toLocaleString()}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* 編集アイコン */}
                <IconButton onClick={() => handleEditOpen(disaster)} color="primary">
                  <EditIcon />
                </IconButton>
                {/* ジャンプアイコン */}
                <IconButton onClick={() => handleJump(disaster.id)} color="primary">
                  <LaunchIcon />
                </IconButton>
                {/* 削除アイコン */}
                <IconButton onClick={() => handleDeleteDisaster(disaster.id)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))
        ) : (
          <Typography variant="body1" className="text-gray-600">
            まだ災害が登録されていません。
          </Typography>
        )}
      </div>

      {/* 編集用ダイアログ */}
      {selectedDisaster && (
        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          fullWidth // ダイアログの幅を全画面幅に
          maxWidth="md" // ダイアログの最大幅を調整
        >
          <DialogTitle>災害の説明を更新</DialogTitle>
          <DialogContent>
            <TextField
              label="災害の説明"
              variant="outlined"
              fullWidth
              multiline
              rows={6} // 説明フィールドをより大きく
              value={disasterDescription}
              onChange={(e) => setDisasterDescription(e.target.value)}
              className="mb-4"
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="secondary">
              キャンセル
            </Button>
            <Button onClick={handleUpdateDisaster} color="primary">
              更新
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default OperateDisasterPage;
