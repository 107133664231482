import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";

// DB関連
import { doc, query, collection, getDoc, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";

// 表示関連
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

// カルーセル
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Avatar, Button } from "@mui/material";
import ModalForm from "./ModalForm";

// アイコン
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DisplaySignUp from "./DisplaySignUp";
import { useNavigate } from "react-router-dom";
import SwitchComponent from "./SwitchComponent";

import ProductQuestion from "./ProductQuestion";
import ProductQAndA from "./ProductQAndA";
import ProductRate from "./ProductRate";
import ProductCusutomerEvalutions from "./ProductCusutomerEvalutions";

import ProductWriteReview from "./ProductWriteReview";
import ProductCustomerReviews from "./ProductCustomerReviews";

import {
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from "react-share";
import RenderUserItem from "./RenderUserItem";

const DisplayProductSmartPhone = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  // ModalForm
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleModalOpen = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  // const queryProduct = query(doc(db, "products", id));
  // const [product] = useDocument(queryProduct);

  const [product, setProduct] = useState("");
  useEffect(() => {
    const init = async () => {
      try {
        const docRef = doc(db, "products", id);
        const snapShot = await getDoc(docRef);
        if (snapShot.exists()) {
          setProduct({ id: docRef.id, ...snapShot.data() });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    if (id) {
      init();
    }
  }, [id]);

  // 販売者(buyer)情報
  const [buyer, setBuyer] = useState("");

  // 瞬間的にでも product?.uidがundefineになるので使えない
  // const queryProductedUser = query(doc(db, "users", product?.uid))
  // const [productedUser] = useDocumentData(queryProductedUser);

  useEffect(() => {
    if (product) {
      getDoc(doc(db, "users", product?.uid)).then((data) => {
        setBuyer(data.data());
      });
    }
  }, [product]);

  // プロダクトの画像
  const queryMyShopImages = query(
    collection(db, `products/${id}/images`)
    // where("uid", "==", auth.currentUser.uid)
  );
  const [images] = useCollection(queryMyShopImages);
  const myShopImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // レビュー取得 評価:Evalution
  const [evalutions, setEvalutions] = useState([]);
  useEffect(() => {
    let arr = [];
    getDocs(collection(db, "products", id, "evalution")).then((docs) => {
      docs?.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setEvalutions(arr);
    });
  }, []);
  // rate取得
  const [average, setAverage] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getRate = () => {
      const count = evalutions?.length;
      let total = 0;
      evalutions?.forEach((doc) => {
        total += doc?.rate;
      });
      setAverage(total / count);
      setLoading(false);
    };
    getRate();
  }, [evalutions]);

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />
      {user && user?.uid === buyer?.uid && (
        <div className=" flex items-center space-x-3 border rounded-md p-2 w-fit">
          <div>掲載</div>
          <SwitchComponent
            id={product?.id}
            target="isPublish"
            collection="products"
            text="表示"
          />
        </div>
      )}

      <div className="p-2 ">
        {/* 戻るボタン？？ */}
        <div
          className="my-1 hover:cursor-pointer"
          onClick={() => navigate("/ddps")}
        >
          ←DDP広場
        </div>
        {/* タイプとレート */}
        <div className="flex text-gray-300">
          <div className="w-7/12">
            {product?.offering}/{product?.target}
          </div>
          <div className="flex items-center justify-end w-5/12">
            <ProductRate />
          </div>
        </div>
        {/* qrコードと各シェア */}
        <div className="flex space-x-2 justify-end items-center">
          <TwitterShareButton url={window.location.href} title={product?.title}>
            <TwitterIcon
              size={24}
              round={true}
              className="hover:opacity-40 duration-150"
            />
          </TwitterShareButton>
          <LineShareButton url={window.location.href} quote={product?.title}>
            <LineIcon
              size={24}
              round={true}
              className="hover:opacity-40 duration-150"
            />
          </LineShareButton>
          <FacebookShareButton
            url={window.location.href}
            quote={product?.title}
          >
            <FacebookIcon
              size={24}
              round={true}
              className="hover:opacity-40 duration-150"
            />
          </FacebookShareButton>
          {/* QRコード */}
          <div
            className="hover:cursor-pointer"
            onClick={(e) => handleModalOpen("displayQRCode")}
          >
            <QrCodeScannerIcon className="hover:opacity-40 duration-150" />
          </div>
        </div>

        {/* タイトル */}
        <div className="flex justify-between my-2">
          <div>{product?.title}</div>
          {/* <div
            className="hover:cursor-pointer"
            onClick={() => handleModalOpen("displayQRCode")}
          >
            <QrCodeScannerIcon />
          </div> */}
        </div>
        {/* 画像　カルーセル */}
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={true}
          className="w-full "
        >
          {myShopImages?.map((image, index) => {
            return (
              <div key={Math.random()}>
                <img
                  src={image.imageURL}
                  alt=""
                  object-fit="cover"
                  className="w-full h-56"
                />
              </div>
            );
          })}
        </Carousel>
        {/* 価格（ルンル） */}
        <div className="my-4"></div>
        <div className="flex items-baseline">
          <div>
            <AttachMoneyIcon />
          </div>
          <div className=" text-7xl">{product?.runru}</div>
          <div>ルンル</div>
        </div>
        {/* 配送情報 */}
        <div className="my-4"></div>
        <div className="flex">
          <div className="font-bold text-xl">配送方法：</div>
          <div>{product?.delivery}</div>
        </div>
        {/* 個数選択 */}
        {/* カートに入れる */}
        {user ? (
          <>
            <div className="my-4"></div>
            <Button
              variant="contained"
              onClick={() => handleModalOpen("reallyBuyDDP", id)}
            >
              交換する
            </Button>
          </>
        ) : (
          <>
            <div className="flex items-center">
              <DisplaySignUp />
              して交換する
            </div>
            {/* <Button>ログイン</Button>して交換する */}
          </>
        )}
        {/* 提供者情報 */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">提供者情報</div>

        <RenderUserItem key={user.uid} user={buyer} highlight={""} />

        {/* <div className="flex flex-col">
          <div className="font-bold text-xl">提供者情報</div>
          <div className="flex items-center rounded-md bg-gray-200 space-x-3 p-2">
            <Avatar src={buyer?.avatar}></Avatar>
            <div>
              {buyer.lastName}
              {buyer.firstName}
            </div>
          </div>
        </div> */}

        {/* DDPの説明 */}
        <div className="my-4"></div>
        <div className="flex flex-col">
          <div className="font-bold text-xl">提供者によるDDPの説明</div>
          <div>{product?.explain}</div>
        </div>
        {/* みんなの評価 */}
        <div className="my-4"></div>
        <div className="flex flex-col">
          <div className="font-bold text-xl">みんなの感想</div>
          <ProductCusutomerEvalutions />
        </div>

        {/* よく一緒に買われているDDP */}
        {/* おすすめDDP */}
        {/* DDPギャラリー */}
        <div className="my-4"></div>
        <div className="flex flex-col">
          <div className="font-bold text-xl">提供者によるギャラリー</div>
          {myShopImages?.map((image, index) => {
            return (
              <div key={Math.random()} className="my-4">
                <img
                  src={image.imageURL}
                  alt=""
                  object-fit="cover"
                  className="w-full h-56"
                />
              </div>
            );
          })}
          {!myShopImages?.length && <div>ギャラリーはありません。</div>}
        </div>

        {/* 質問 */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">質問する</div>
        <ProductQuestion />

        {/* 質問と回答 */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">質問と回答</div>
        <ProductQAndA />
        {/* みんなのレビュー */}
        <ProductWriteReview />


          {/* 2024.10.07削除要請 */}
        {/* <div className="my-4"></div>
        <div className="font-bold text-xl">みんなのレビュー</div>
        <ProductCustomerReviews /> */}

        {/* このDDPを買った人はこんなDDPも買っています */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">
          このDDPを受け取った人はこんなDDPも交換しています
        </div>
        <div>実装中です</div>
      </div>
    </>
  );
};

export default DisplayProductSmartPhone;
