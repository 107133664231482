import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import {
  getDoc,
  doc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

import HotelIcon from "@mui/icons-material/Hotel";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SpaIcon from "@mui/icons-material/Spa";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import HealingIcon from "@mui/icons-material/Healing";
import { LoadScript } from "@react-google-maps/api";
import { useLocation, useNavigate } from "react-router-dom";

const RequestShop = ({ setOpen }) => {
  // const [address, setAddress] = useState("");
  // ユーザ取得関連
  const [user, setUser] = useState("");
  // ユーザ取得とプロフィール選択オプション
  useEffect(() => {
    // ユーザ取得
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
      if (doSnap.exists) {
        setUser(doSnap.data());
      }
    });
  }, []);

  const shopData = {
    レストラン: {
      type: "shop",
      industry: "restaurant",
      icon: RestaurantIcon,
      src: "https://maps.google.com/mapfiles/ms/micons/restaurant.png",
    },
    ホテル: {
      type: "shop",
      industry: "hotel",
      icon: HotelIcon,
      src: "https://maps.google.com/mapfiles/ms/micons/lodging.png",
    },
    病院: {
      type: "shop",
      industry: "hospital",
      icon: LocalHospitalIcon,
      src: "https://maps.google.com/mapfiles/kml/pal3/icon46.png",
    },
    ヒーリング: {
      type: "shop",
      industry: "healing",
      icon: HealingIcon,
      src: "",
    },
    占い: { type: "shop", industry: "fortune", icon: BedtimeIcon, src: "" },
    マッサージ: { type: "shop", industry: "massage", icon: SpaIcon, src: "" },
  };

  const location = useLocation();
  // console.log("location.pathname =>", location.pathname);

  const [place, setPlace] = useState();

  // お店掲載申請コンテナ
  const [requestShopData, setRequestShopData] = useState({
    name: "",
    address: "",
    tel: "",
    url: "",
    lat: "",
    lng: "",
    RequestShopError: "",
    RequestShopLoading: "",
  });
  const {
    name,
    address,
    tel,
    url,
    lat,
    lng,
    RequestShopError,
    RequestShopLoading,
  } = requestShopData;

  const geocode = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: place }, (results, status) => {
      // console.log("results =>", results);
      if (status === "OK") {
        setRequestShopData({
          ...requestShopData,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }
    });
  };
  const changeLocationName = (event) => {
    // console.log("event =>", event.target.value);
    setRequestShopData({ ...requestShopData, address: event.target.value });
    setPlace(event.target.value);
    geocode();
  };

  // console.log("requestShopData =>", requestShopData);

  const navigate = useNavigate();

  // お店掲載申請値変更
  const handleChangeShop = (e) => {
    if (e.target.name === "industry") {
      setRequestShopData({
        ...requestShopData,
        [e.target.name]: shopData[e.target.value].industry,
        type: shopData[e.target.value].type,
        src: shopData[e.target.value].src,
        indust: e.target.value,
      });
      return;
    }

    setRequestShopData({
      ...requestShopData,
      [e.target.name]: e.target.value,
    });
  };

  // お店掲載申請処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setRequestShopData({
      ...requestShopData,
      RequestShopError: "",
      RequestShopLoading: true,
    });
    console.log("登録処理！");

    try {
      // 登録
      const docRef = await addDoc(collection(db, "requestShop"), {
        name,
        address,
        tel,
        url,
        lat,
        lng,
        type: "userShop",
        isPublish: false,
        isPermission: false,
        owner: user.uid,
        createdAt: serverTimestamp(),
        avatar: "",
        uid: auth.currentUser.uid,
        ownerName: user?.lastName + user?.firstName,
      });
      setRequestShopData({
        ...requestShopData,
        RequestShopError: null,
      });
      // setOpen(false);
      if (window.location.pathname === "/shopApplication") {
        navigate("/thanksForApplication");
        return;
      }
      // console.log("window.location =>",window.location.pathname)
      window.location.reload();
    } catch (err) {
      setRequestShopData({
        ...requestShopData,
        RequestShopError: err.message,
        RequestShopLoading: false,
      });
    }
  };
  // お店掲載申請関連
  const [fromProvider, setFromProvider] = useState(false);

  // お店申請時に選択する職業一覧
  const storesWithLabel = () => {
    const Icon = ({ Icon }) => {
      return <>{Icon && <Icon className="h-4 w-4" />}</>;
    };

    const StoresLabel = ({ value }) => {
      return (
        <>
          <div className="flex items-center space-x-2 rounded-md">
            <Icon Icon={shopData[value].icon} />
            <span>{value}</span>
          </div>
        </>
      );
    };

    const createStores = (value, type) => {
      return {
        label: (
          <>
            <StoresLabel value={value} />
          </>
        ),
        name: value,
        value: value,
      };
    };
    const stores = [
      createStores("ホテル", "hotel"),
      createStores("レストラン", "restaurant"),
      createStores("マッサージ", "massage"),
      createStores("占い", "fortune"),
      createStores("ヒーリング", "healing"),
      createStores("病院", "hospital"),
    ];
    return stores;
  };

  // const [isMapLoaded, setIsMapLoaded] = useState(false);

  // useEffect(() => {
  //   const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  //   const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;

  //   console.log("googleMapsApiKey =>", googleMapsApiKey);

  //   const script = document.createElement("script");
  //   script.src = googleMapsUrl;
  //   script.async = true;
  //   script.onload = () => setIsMapLoaded(true);
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // if (!isMapLoaded) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <div className="flex flex-col justify-center max-w-md">
        <div className="mb-3">お店掲載申請</div>
        <form onSubmit={setRequestDataShop}>
          {/* お店の名前 */}
          <TextField
            id="outlined-required"
            name="name"
            label="お店の名前"
            defaultValue=""
            size="small"
            placeholder="〇〇商店"
            className=" w-full"
            onChange={handleChangeShop}
            required
            disabled={fromProvider}
          />

          {/* お店の住所 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="address"
              label="住所"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={(event) => changeLocationName(event)}
              // onBlur={addressOnBlur}
              required
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>

          {/* 経度 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              label="経度"
              value={lng}
              size="small"
              className="w-full my-4"
              disabled
            ></TextField>
          </div>
          <div className="my-4">
            <TextField
              id="outlined-required"
              label="緯度"
              value={lat}
              size="small"
              onChange={changeLocationName}
              disabled
            ></TextField>
          </div>

          {location?.pathname === "/profile" && (
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            ></LoadScript>
          )}

          {/* お店の電話番号 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="tel"
              type="number"
              label="電話番号 -(ハイフン)不要"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={handleChangeShop}
              required
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>

          {/* ホームページURL */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="url"
              label="ホームページURL"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={handleChangeShop}
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>

          {/* お店の種類 */}
          <div className="my-4">
            {/* <TextField
            id="outlined-select-currency"
            name="industry"
            label="どんなお店？"
            defaultValue=""
            className="w-full"
            size="small"
            onChange={handleChangeShop}
            select
            required
          >
            {storesWithLabel().map((option) => (
              <MenuItem key={option.value} value={option.value} dense>
                {option.label}
              </MenuItem>
            ))}
          </TextField> */}

            {/* ひとこと */}
            {/* <div className="my-4">
            <TextField
              id="onething"
              name="onething"
              label="ひとこと"
              defaultValue=""
              placeholder="例：事前に連絡くれると嬉しいです＾＾"
              size="small"
              variant="standard"
              onChange={handleChangeShop}
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div> */}
          </div>

          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={RequestShopLoading}
          >
            {RequestShopLoading ? "送信しています..." : "申請"}
          </button>
        </form>
        <div className=" text-gray-600 p-2">
          ※お店の業種の設定や緯度経度の設定は、お店申請後にお店専用ページより行えます。
        </div>
      </div>
    </>
  );
};

export default RequestShop;
