import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";

const RunruSpecialTransfer = ({ onClose }) => {
  const [usersCollection] = useCollection(collection(db, "users"));
  const users = usersCollection?.docs.map((user) => ({
    id: user.data().uid,
    ...user.data(),
  }));

  const [searchTo, setSearchTo] = useState("");
  const [selectedTo, setSelectedTo] = useState(null);
  const [formState, setFormState] = useState({
    amount: "",
    error: "",
    loading: false,
  });
  const { amount, error, loading } = formState;

  // 確認ダイアログ用の状態
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 完了メッセージ用の状態
  const [openCompletionDialog, setOpenCompletionDialog] = useState(false);

  const isSubmitDisabled = !amount || !selectedTo;

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleCloseCompletionDialog = () => {
    setOpenCompletionDialog(false);
    onClose(); // 全体のダイアログを閉じる
  };

  // 登録処理
  const handleSubmit = async () => {
    setFormState({ ...formState, loading: true });

    try {
      await addDoc(collection(db, "runru"), {
        to: selectedTo.id,
        from: "運営", // 固定値 "運営"
        amount: Number(amount),
        cause: "特別付与", // 固定値 "特別付与"
        createdAt: serverTimestamp(),
      });
      setFormState({ amount: "", error: "", loading: false });
      setOpenConfirmDialog(false); // 確認ダイアログを閉じる
      setOpenCompletionDialog(true); // 完了ダイアログを開く
    } catch (err) {
      setFormState({ ...formState, error: err.message, loading: false });
    }
  };

  const filterUsers = (searchText) => {
    if (!searchText) return [];
    return users?.filter((user) =>
      [user.firstName, user.lastName, user.uid, user.email]
        .some((field) => field?.toLowerCase().includes(searchText.toLowerCase()))
    );
  };

  return (
    <>
      <h2>ルンル特別移動</h2>
      <div style={{ width: "100%", maxWidth: "900px" }}>
        {/* 「誰へ」検索と選択 */}
        <div>
          <TextField
            label="誰へ"
            fullWidth
            value={searchTo}
            onChange={(e) => setSearchTo(e.target.value)}
            placeholder="名前、UID、メールで検索"
            margin="normal"
          />
          <List style={{ maxHeight: "200px", minHeight: "200px", overflowY: "auto" }}>
            {filterUsers(searchTo).map((user) => (
              <ListItem
                key={user.id}
                button
                selected={selectedTo?.id === user.id}
                onClick={() => setSelectedTo(user)}
                style={{
                  backgroundColor: selectedTo?.id === user.id ? "#e0f7fa" : "transparent",
                  padding: "4px 8px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Checkbox
                  edge="start"
                  checked={selectedTo?.id === user.id}
                  tabIndex={-1}
                  disableRipple
                  onChange={() => setSelectedTo(user)}
                  color="primary"
                />
                <Avatar src={user.avatar} alt={`${user.firstName} ${user.lastName}`} />
                <ListItemText
                  primary={`${user.firstName || ""} ${user.lastName || ""}`}
                  secondary={`${user.uid} | ${user.email}`}
                  style={{ marginLeft: "8px" }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </div>

      {/* 価格 */}
      <TextField
        name="amount"
        label="価格(ルンル)"
        type="number"
        fullWidth
        margin="normal"
        value={amount}
        onChange={(e) => setFormState({ ...formState, amount: e.target.value })}
        onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
        disabled={loading}
        required
      />

      {/* 確認ダイアログ */}
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" gap={2} mb={2}>
            <Avatar src={selectedTo?.avatar} alt={selectedTo?.firstName} />
            <Typography variant="body1">
              {selectedTo?.firstName} {selectedTo?.lastName} さんへ
            </Typography>
          </Box>
          <DialogContentText>
            <strong>{amount}</strong> の Runru を「特別付与」として送ります。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            キャンセル
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* 完了メッセージダイアログ */}
      <Dialog open={openCompletionDialog} onClose={handleCloseCompletionDialog}>
        <DialogTitle>完了</DialogTitle>
        <DialogContent>
          <DialogContentText>ルンル特別移動処理が完了しました。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCompletionDialog} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* 登録ボタン */}
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          キャンセル
        </Button>
        <Button onClick={handleOpenConfirmDialog} color="primary" variant="contained" disabled={isSubmitDisabled || loading}>
          {loading ? "送信しています..." : "登録"}
        </Button>
      </div>
    </>
  );
};

export default RunruSpecialTransfer;
