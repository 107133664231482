import React, { useState } from "react";
import { collection, doc, updateDoc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import AddComponent from "./AddComponent";
import { Dialog, DialogContent, DialogTitle, Button, TextField, MenuItem } from "@mui/material";
import RunruForceTransfer from "./RunruForceTransfer";
import RunruSpecialTransfer from "./RunruSpecialTransfer"; // 追加：特別付与に関するコンポーネント

const OperateRUNRU = () => {
  // 村民取得
  // const [villagersCollecion, setVillagersCollection] = useState([]);
  // const [villagers, setVillagers] = useState([]);
  // const [loadingV, setLoadingV] = useState(true);
  // useEffect(() => {
  //   const unscribe = () => {
  //     getDocs(collection(db, "users")).then((snapshot) => {
  //       setVillagersCollection(snapshot.docs);
  //       setLoadingV(false);
  //     });
  //   };
  //   return unscribe();
  // }, [loadingV]);
  // useEffect(() => {
  //   if (villagersCollecion.length) {
  //     const villagers = villagersCollecion?.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setVillagers(villagers);
  //   }
  // }, [villagersCollecion]);

  // ユーザーズリスト作成
  // const usersList = [];
  // villagers?.map((user, index) => {
  //   usersList[user.uid] = user;
  // });

  // ルンルデータ取得
  // const [runruCollection, setRunruCollection] = useState([]);
  // const [runrus, setRunrus] = useState([]);
  // const [loadingR, setLoadingR] = useState(true);
  // useEffect(() => {
  //   const unscribe = () => {
  //     getDocs(collection(db, "runru")).then((snapshot) => {
  //       setRunruCollection(snapshot.docs);
  //       setLoadingR(false);
  //     });
  //   };
  //   return unscribe();
  // }, [loadingR]);
  // useEffect(() => {
  //   if (runruCollection.length) {
  //     const arr = runruCollection?.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setRunrus(arr);
  //   }
  // }, [runruCollection]);

  // 取引(deal)データ取得
  // const [dealCollection, setDealCollection] = useState([]);
  // const [deals, setDeals] = useState([]);
  // const [loadingD, setLoadingD] = useState(true);
  // useEffect(() => {
  //   const unscribe = () => {
  //     getDocs(collection(db, "deal")).then((snapshot) => {
  //       setDealCollection(snapshot.docs);
  //       setLoadingD(false);
  //     });
  //   };
  //   return unscribe();
  // }, [loadingD]);
  // useEffect(() => {
  //   if (dealCollection.length) {
  //     const arr = dealCollection?.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setDeals(arr);
  //   }
  // }, [dealCollection]);

  // let now = new Date();

  // const periodAll = now.setDate(now.getDate());
  // const period90 = now.setDate(now.getDate()- 90);
  // const periodA180 = now.setDate(now.getDate()-180);
  // const period365 = now.setDate(now.getDate()-365);

  // 履歴と残高追加
  // villagers?.map((user, index) => {
  //   // 履歴追加
  //   villagers[index].history = getRunruHistory(user, runrus, usersList, period90);
  //   villagers[index].historyDeal = getDealHistory(user, deals, usersList);
  //   // 残高追加
  //   villagers[index].balance = getRunruBalance(user, runrus,deals, usersList);
  //   villagers[index].balance90 = getRunruBalance(user, runrus,deals, usersList, period90);
  //   villagers[index].balance180 = getRunruBalance(user, runrus,deals, usersList, periodA180);
  //   villagers[index].balance365 = getRunruBalance(user, runrus,deals, usersList, period365);
  // });

  // ルンルの期限
  const [period, setPeriod] = useState("");
  const [runruPeriod] = useDocumentData(doc(db, "env", "runruPeriod"));

  const periodChange = (e) => {
    setPeriod(e.target.value);
  };

  const updatePeriod = () => {
    updateDoc(doc(db, "env", "runruPeriod"), {
      period,
    });
  };

  // ルンル特別付与のダイアログ状態管理
  const [openSpecialTransfer, setOpenSpecialTransfer] = useState(false);

  // ダイアログを開くハンドラー
  const handleOpenSpecialTransfer = () => {
    setOpenSpecialTransfer(true);
  };

  // ダイアログを閉じるハンドラー
  const handleCloseSpecialTransfer = () => {
    setOpenSpecialTransfer(false);
  };

  // Dialogの状態管理
  const [open, setOpen] = useState(false);

  // Dialogを開くハンドラー
  const handleOpen = () => {
    setOpen(true);
  };

  // Dialogを閉じるハンドラー
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      /> */}

      {/* ルンルの期限設定 */}
      <div className="text-xl mb-2">
        ルンルの期限設定 現在：{runruPeriod?.period || "無制限"}
      </div>
      <div className="w-1/2">
        <TextField
          size="small"
          sx={{ width: 180 }}
          value={period}
          onChange={periodChange}
          label="ルンルの期限(日)"
          select
        >
          <MenuItem dense value="">
            無制限
          </MenuItem>
          <MenuItem dense value={90}>
            90
          </MenuItem>
          <MenuItem dense value={180}>
            180
          </MenuItem>
          <MenuItem dense value={365}>
            365
          </MenuItem>
        </TextField>
        <Button variant="text" onClick={updatePeriod}>
          変更する
        </Button>
      </div>

      {/* 付与関連 */}
      <div className="text-xl my-2">ルンル付与</div>
      <div className="sm:grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 space-y-2 sm:space-y-0 gap-2 mx-2 mb-5">
        {/* 定期付与 */}
        <div className="flex flex-col border rounded-md p-2 justify-between">
          <div className="text-xl">村民さん全員にルンル付与</div>
          <div className="text-sm text-gray-300">
            運営から村民さん(村民権限があって、アカウント停止されてない人)全員に付与します
          </div>
          <AddComponent
            value="村民さん全員にルンル付与"
            target="runruAddForVillagers"
          />
        </div>

        {/* 強制移動 */}
        {/* <div className="flex flex-col border rounded-md p-2 justify-between">
          <div className="text-xl ">ルンル強制移動</div>
          <div className="text-sm text-gray-300">
            「誰か」から「誰か」へ、ルンル強制的に移動させます
          </div>
          <AddComponent value="ルンル強制移動" target="runruAddOrPull" />
        </div> */}

        {/* 強制移動 */}
        <div className="flex flex-col border rounded-md p-2 justify-between">
          <div className="text-xl ">ルンル強制移動</div>
          <div className="text-sm text-gray-300">
            「誰か」から「誰か」へ、ルンル強制的に移動させます
          </div>
          <Button variant="contained" onClick={handleOpen}>
            ルンル強制移動
          </Button>
        </div>

        {/* 全体付与 */}
        <div className="flex flex-col border rounded-md p-2 justify-between">
          <div className="text-xl ">ルンル全体付与</div>
          <div className="text-sm text-gray-300">
            「"村民権限がある"などにかかわらず」、ルンルを全員に配布します
          </div>
          <AddComponent value="ルンル全体付与" target="runruAddForAll" />
        </div>
      </div>

      <div className="sm:grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 space-y-2 sm:space-y-0 gap-2 mx-2 mb-5">
        {/* ルンル特別付与 */}
        <div className="flex flex-col border rounded-md p-2 justify-between">
          <div className="text-xl">ルンル特別付与</div>
          <div className="text-sm text-gray-300">
            運営から特定のユーザにルンルを特別付与
          </div>
          <Button variant="contained" onClick={handleOpenSpecialTransfer}>
            特別付与
          </Button>
        </div>
      </div>

      {/* 特別付与のダイアログ */}
      <Dialog open={openSpecialTransfer} onClose={handleCloseSpecialTransfer} fullWidth maxWidth="md">
        <DialogTitle>ルンル特別付与</DialogTitle>
        <DialogContent
          dividers
          sx={{
            overflowX: "auto", // 横スクロールを有効化
            minWidth: { xs: "600px", sm: "auto" }, // モバイルの場合は最低幅を設定
          }}
        >
          {/* 特別付与のコンポーネント */}
          <RunruSpecialTransfer onClose={handleCloseSpecialTransfer} />
        </DialogContent>
      </Dialog>

      {/* 強制移動ダイアログ */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>ルンル強制移動</DialogTitle>
        <DialogContent
          dividers
          sx={{
            overflowX: "auto", // 横スクロールを有効化
            minWidth: { xs: "600px", sm: "auto" }, // モバイルの場合は最低幅を設定
          }}
        >
          <RunruForceTransfer onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OperateRUNRU;
